import React from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { Footer, Container } from "./style/index";
import ContactInfo from "./contact-via";
import CommpanyInfo from "./company-info";
import { Body1 } from "src/common/theme";

function index() {
  return (
    <Footer>
      <RootWrapper>
        <Container>
          <ContactInfo />
          <CommpanyInfo />
        </Container>
        <Body1 color="white" style={{ padding: "23px 0 10px 0" }}>
          Copyright © 2022 Angel Korean. All Rights Reserved. Developed by <a style={{ color: "white" }} href="https://bitpointnepal.com">Bitpoint Pvt. Ltd</a>
        </Body1>
      </RootWrapper>
    </Footer>
  );
}
export default index;
