import React from "react";
import Profile from "src/common/images/profile.webp";
import { H6, Body1 } from "src/common/theme";
import { ProfileWrapper } from "../style";
function index() {
  return (
    <ProfileWrapper>
      <div
        style={{
          width: "249px",
          height: "412px",
          objectFit: "cover",
        }}
      >
        <img src={Profile} width="100%" height="100%" />
      </div>
      <H6 color="black" style={{ paddingTop: "0.5em" }}>
        Toran Bhandari
      </H6>
      <Body1 style={{ borderBottom: "1px solid #E5E5E5", padding: "9px 0" }}>
        Teacher
      </Body1>
      <Body1 style={{ marginTop: "5px" }}>Contact number:</Body1>
      <H6>
        <a href="tel:+(977) 9848254922" style={{ color: "black" }}>
          +977 9848254922
        </a>
      </H6>
    </ProfileWrapper>
  );
}

export default index;
