import { BookSvg, MusicSvg } from 'src/common/svg'
import { H5 } from 'src/common/theme'
import { useAppSelector } from 'src/hooks/hooks'
import { QuestionEnum } from 'src/model/modelSet'
import QuestionNumber from './question-number/index'
import { QuestionWrapper } from './style/index'

interface TotalQuestionProps {
  activeHandler: (step: number) => void
}

function TotalQuestion({ activeHandler }: TotalQuestionProps) {
  const result = useAppSelector((state) => state.result.result)

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          textAlign: 'center',
        }}
      >
        {result && (
          <>
            <div
              style={{
                flex: '0 0 50%',
                maxWidth: '50%',
                // padding: '0 15px',
              }}
            >
              <QuestionWrapper>
                <BookSvg />
                <H5>
                  읽기 (
                  {
                    result!.questionAns.filter(
                      (each) => (each.type === QuestionEnum.text && each.option_type !== QuestionEnum.audio) || (each.type === QuestionEnum.image && each.option_type !== QuestionEnum.audio)
                    ).length
                  }
                  Questions)
                </H5>
              </QuestionWrapper>
              <QuestionNumber
                activeHandler={activeHandler}
                allQuestionAns={result!.questionAns.filter(
                  (each) => (each.type === QuestionEnum.text && each.option_type !== QuestionEnum.audio) || (each.type === QuestionEnum.image && each.option_type !== QuestionEnum.audio)
                )}
              />
            </div>
            <div
              style={{
                flex: '0 0 50%',
                maxWidth: '50%',
                padding: '0 15px',
              }}
            >
              <QuestionWrapper>
                <MusicSvg />
                <H5>읽기 ({result!.questionAns.filter((each) => each.type === QuestionEnum.audio || each.option_type === QuestionEnum.audio).length} Questions)</H5>
              </QuestionWrapper>
              <QuestionNumber
                activeHandler={activeHandler}
                allQuestionAns={result!.questionAns.filter((each) => each.type === QuestionEnum.audio || each.option_type === QuestionEnum.audio || each.type === QuestionEnum.imgAudio)}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default TotalQuestion
