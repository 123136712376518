import styled from "styled-components";

export const Typography = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 23px;
  color: #0d5ef4;
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  min-height: 560px;
  padding: 110px 50px;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 30px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #ecf1f9;
  height: 60%;

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 60%;
  }

  @media (max-width: 375px) {
    width: 100%;
  }
`;
