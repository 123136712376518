import styled from "styled-components";

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 1070px;
  height: 603px;
  display: flex;
  flex-shrink: 2;
  flex-wrap: wrap;
  // border: 2px solid;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 680px;
  }
  @media (max-width: 600px) {
    width: 100%;
    // max-width: 580px;
  }
  @media (max-width: 375px) {
    width: 100%;
    max-width: 300px;
  }
`;

export const ProfileWrapper = styled.div`
  width: 279px;
  flex-shrink: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  @media (max-width: 900px) {
    display: none;
  }
`;
