import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Body1, Body2, H2, H5, H6 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Box, Container, PracticeNowButton, Wrapper } from "../style/index";

function PopularModalSets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("token");
  const featured = useAppSelector((state) => state.modalSet.exams);

  useEffect(() => {
    apiService.getFeaturedModalSet(dispatch);
  }, []);

  return (
    <div
      style={{
        background: "#F3F7FB",
      }}
    >
      <RootWrapper style={{ padding: "113px 0" }}>
        <Box>
          <Body1 color="blue" style={{ fontWeight: "500" }}>
            POPULAR SETS
          </Body1>
          <H2 style={{ marginTop: "10px" }}>Explore Our Model Sets</H2>

          <div
            style={{
              marginTop: "50px",
              display: "flex",
              flexWrap: "wrap",
              gap: "18px",
            }}
          >
            {featured.map((item, index) => {
              return (
                <Wrapper key={index}>
                  {item.type === "free" && (
                    <Container>
                      <Body2
                        style={{
                          background: `${
                            item.type === "free" ? "red" : "blue"
                          }`,
                          color: "white",
                          width: "60px",
                          height: "24px",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        {item.type}
                      </Body2>

                      <Body1 color="grey">{item.title}</Body1>
                      <H5 color="grey">{item.subtitle}</H5>
                      {/* <H6 color="blue">Rs {item.price}</H6> */}
                      <H6
                        color="blue"
                        style={{
                          borderBottom: "1px dashed #D0D7E3",
                          paddingBottom: "15px",
                        }}
                      >
                        {item.model_type}
                      </H6>
                      <PracticeNowButton
                        color={item.type === "free" ? "red" : "blue"}
                        onClick={() => {
                          if (item.type === "free") {
                            isLogin
                              ? navigate("/examineer", {
                                  state: {
                                    modelSetId: item.id,
                                    modelSet: item,
                                  },
                                })
                              : navigate("/login");
                          } else {
                            navigate("/payment", {
                              state: {
                                price: item.price,
                                modelSetId: item.id,
                              },
                            });
                          }
                        }}
                      >
                        <Body2 style={{ color: "#FFFFFF" }}>
                          {item.type === "free" ? "PRACTICE NOW" : "BOOK NOW"}
                        </Body2>
                      </PracticeNowButton>
                    </Container>
                  )}
                </Wrapper>
              );
            })}
          </div>

          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              // border: '1px solid red'
            }}
          >
            <Button
              variant="contained"
              sx={{ padding: "15px 38px" }}
              onClick={() => (isLogin ? navigate("/exam") : navigate("/login"))}
            >
              LEARN MORE
            </Button>
          </div>
        </Box>
      </RootWrapper>
    </div>
  );
}

export default PopularModalSets;
