import React, { useState } from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { Body1, H2, UnderLine } from "src/common/theme";
import { Button, CircularProgress } from "@mui/material";
import TextField from "src/common/input-field/TextField";
import { Formik } from "formik";
import { apiService } from "src/http/api-service";
import { useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { HeaderFooter } from "src/App";

function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  return (
    <HeaderFooter>
      <RootWrapper>
        <div
          style={{
            margin: "100px auto",
            padding: "0 20px",
            width: "100%",
            maxWidth: "1155px",
            height: "789px",
            background: "#F3F7FB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "549px",
              textAlign: "center",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <H2>Sign up</H2>
              <UnderLine />
              <Body1>
                Enter the following details to register your account!
              </Body1>
            </div>
            <Formik
              initialValues={{
                name: "",
                email: "",
                // primary_number: "",
                password: "",
                confirm_password: "",
                submit: null,
              }}
              onSubmit={async (values) => {
                setLoading(true);
                try {
                  const res = await apiService.signupStudent(values);
                  res?.data && navigate("/login");
                  toast("Successfully register!.", {
                    type: "success",
                  });
                  setLoading(false);
                } catch (error) {
                  let errorMessage = "Failed to register!";

                  if (error) {
                    errorMessage = (error as any).errors
                      .map((err: any) => err.message)
                      .join(" ");
                  }
                  toast(errorMessage, {
                    type: "error",
                  });
                  setLoading(false);
                }
              }}
            >
              {({
                setFieldValue,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "25px",
                      marginTop: "30px",
                    }}
                  >
                    <TextField
                      type="text"
                      name="name"
                      required
                      onChange={handleChange}
                      placeholder="Full Name *"
                      value={values.name}
                      className="login-form"
                    />
                    <TextField
                      type="email"
                      name="email"
                      required
                      onChange={handleChange}
                      placeholder="Email Address/Phone Number *"
                      value={values.email}
                      className="login-form"
                    />
                    <TextField
                      type="password"
                      name="password"
                      required
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      className="login-form"
                    />
                    <TextField
                      type="password"
                      required
                      onChange={handleChange}
                      name="confirm_password"
                      placeholder="Confirm Password"
                      value={values.confirm_password}
                      className="login-form"
                    />

                    <Button
                      disabled={loading ? true : false}
                      type="submit"
                      variant="contained"
                    >
                      {loading ? <CircularProgress /> : "SUBMIT"}
                    </Button>
                    <Body1>
                      Have an account?
                      <NavLink to={"/login"}> login</NavLink>
                    </Body1>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </RootWrapper>
    </HeaderFooter>
  );
}

export default SignUp;
