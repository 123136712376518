import { Article } from 'src/model/article'
import { ModelSet } from 'src/model/modelSet'
import { paymentHistory } from 'src/model/payment'
import { Publication } from 'src/model/publication'
import { Result } from 'src/model/result'
import { Slider } from 'src/model/slider'
import { User } from 'src/model/user'
import { AppDispatch } from 'src/store'
import { getBlogs } from 'src/store/reducers/blog'
import { getCourse } from 'src/store/reducers/classSlice'
import { getDownload } from 'src/store/reducers/download'
import { getExamModel, getFeaturedModalSet } from 'src/store/reducers/modelSetSlice'
import { getPublication } from 'src/store/reducers/publication'
import { getExamResult, getPaidResult, getPaymentHistory, selectedAnswer, submitAnswer, updateAudio } from 'src/store/reducers/resultSlice'
import { getReview } from 'src/store/reducers/reviewSlilce'
import { getSlider } from 'src/store/reducers/slider'
import { getProfile, getStudent, updateProfile } from 'src/store/reducers/studentProfileSlice'
import { getTabletMode } from 'src/store/reducers/tabletModeSlice'
import { ClassResponse } from 'src/types/response'
import { ResponseUtils, SingleResponseUtils } from 'src/types/response/utils'
import { api } from './api'

export const apiService = {
  contactUs: async (data: any) => {
    try {
      const response = await api.post(`/contact-us`, data)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },

  signupStudent: async (data: any) => {
    try {
      const response = await api.post(`/students/signup`, data)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  loginStudent: async (data: any) => {
    try {
      const response = await api.post(`/students/signin`, data)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getStudent: async (dispatch: AppDispatch, id: string) => {
    try {
      const response = await api.get(`/students/${id}`)
      if (response) {
        dispatch(getStudent(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getCourse: async (dispatch: AppDispatch, params: string) => {
    try {
      const response: ClassResponse = await api.get(`/classes?type=${params}`)
      if (response) {
        dispatch(getCourse(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  applyForClass: async (dispatch: AppDispatch, data: any) => {
    try {
      const response: ClassResponse = await api.post(`/enrollments/${data.course}`, data)
      if (response) {
        // dispatch(getCourse(response));
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getProfile: async (dispatch: AppDispatch) => {
    try {
      const response = await api.get('/students/profile')
      if (response) {
        dispatch(getProfile(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  updateProfile: async (dispatch: AppDispatch, data: any) => {
    try {
      const response: SingleResponseUtils<User> = await api.patch('students/update-profile', data)
      if (response) {
        dispatch(updateProfile(response))

        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getExamModel: async (
    dispatch: AppDispatch,
    query: {
      model_type: string
      rowsPerPage: number
      page: number
      select?: string
      sortBy?: string
    }
  ) => {
    const { rowsPerPage, page, model_type } = query
    try {
      const response: ResponseUtils<ModelSet> = await api.get(`/modelsets?page=${page}&limit=${rowsPerPage}&model_type=${model_type}`)

      if (response) {
        dispatch(getExamModel(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getExamResult: async (dispatch: AppDispatch, id: string) => {
    try {
      const response: SingleResponseUtils<Result> = await api.get(`/exam/${id}`)
      if (response) {
        dispatch(getExamResult(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getPaidResult: async (dispatch: AppDispatch) => {
    try {
      const response: ResponseUtils<Result> = await api.get(`/mytest`)

      if (response) {
        dispatch(getPaidResult(response))
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getReview: async (dispatch: AppDispatch, id: any) => {
    try {
      const response: SingleResponseUtils<Result> = await api.get(`/mytest/${id}`)
      if (response) {
        dispatch(getReview(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  selectedAnswer: async (dispatch: AppDispatch, data: { resultId: string; question: string; selectedAns: string }) => {
    try {
      const response = await api.post('exam/take-exam', data)

      if (response) {
        dispatch(selectedAnswer(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  submitAnswer: async (dispatch: AppDispatch, resultId?: string) => {
    try {
      const response = await api.post('/exam/submit', { resultId })
      if (response) {
        dispatch(submitAnswer(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getTimer: async (dispatch: AppDispatch, resultId: string) => {
    try {
      const response = await api.post('/exam/settime', { resultId: resultId })
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  //Payment
  paymentVerificaiton: async (data: { amount: string | null; refId: string | null; paymentId: string | null }) => {
    try {
      const response = await api.post('/payments', data)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getPaymentHistory: async (dispatch: AppDispatch) => {
    try {
      const response: ResponseUtils<paymentHistory> = await api.get(`/payments/history`)
      if (response) {
        dispatch(getPaymentHistory(response))
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getFeaturedModalSet: async (dispatch: AppDispatch) => {
    try {
      const response: ResponseUtils<ModelSet> = await api.get(`modelsets/featured`)

      if (response) {
        dispatch(getFeaturedModalSet(response))
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  updateAudio: async (dispatch: AppDispatch, data: any) => {
    try {
      const response = await api.patch('/exam/audioplay', {
        ...data,
      })
      if (response) {
        dispatch(updateAudio(response))
        return Promise.resolve(response.data)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },

  getSlider: async (dispatch: AppDispatch) => {
    try {
      const response: ResponseUtils<Slider> = await api.get(`/slider`)
      if (response) {
        dispatch(getSlider(response))
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getPublication: async (dispatch: AppDispatch) => {
    try {
      const response: ResponseUtils<Publication> = await api.get(`/publication`)
      if (response) {
        dispatch(getPublication(response))
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getArticle: async (dispatch: AppDispatch, LinkType: any) => {
    try {
      const response: ResponseUtils<Article> = await api.get(`/article?type=${LinkType} `)
      if (response) {
        LinkType === 'blogLink' && dispatch(getBlogs(response))
        LinkType === 'publicationLink' && dispatch(getPublication(response))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getDownloadable: async (
    dispatch: AppDispatch,
    query: {
      type: string
      rowsPerPage: number
      page: number
      select?: string
      sortBy: string
    }
  ) => {
    const { type, rowsPerPage, page, sortBy } = query
    try {
      if (type === 'all') {
        const response = await api.get(`/download?page=${page}&limit=${rowsPerPage}&sort=${sortBy}`)

        if (response) {
          dispatch(getDownload(response))
          return Promise.resolve(response)
        }
      } else {
        const response = await api.get(`/download?type=${type}&page=${page}&limit=${rowsPerPage}&sort=${sortBy}`)

        if (response) {
          dispatch(getDownload(response))
          return Promise.resolve(response)
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getTabletMode: async (dispatch: AppDispatch) => {
    try {
      const response = await api.get('/tablet-mode')

      if (response) {
        dispatch(getTabletMode(response))
        return Promise.resolve(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
