import Card from "src/common/card/index";
import { H2, H6 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useEffect } from "react";
import { apiService } from "src/http/api-service";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";

function Index() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchArticle = async () => {
      const res = await apiService.getPublication(dispatch);
    };
    fetchArticle();
  }, []);

  const publications = useAppSelector((state) => state.publication.publication);

  return (
    <RootWrapper>
      <div style={{ marginBottom: "150px" }}>
        <H2
          style={{
            margin: "95px 0 52px 0",
            textAlign: "center",
          }}
        >
          Our Publications
        </H2>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {publications && publications.length > 0 ? (
            publications.map((publication) => {
              return (
                <>
                  <Card
                    image={publication.image}
                    // subTitle={subTitle}
                    // url={publication.url}
                  />
                </>
              );
            })
          ) : (
            <H6>Comming Soon......</H6>
          )}
        </div>
      </div>
    </RootWrapper>
  );
}

export default Index;
