import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Esewa from 'src/common/images/esewa-icon.png'
import { PracticeNowButton } from 'src/common/modal-button'
import { Body1, Body2, H2, H5, H6 } from 'src/common/theme'
import { useAppSelector } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'
import '../../../index.css'
import { Btn, Container, Textdiv, Top, Wrapper } from './stye'

const Switch = ['ALL', 'UBT', 'CBT']

function ExamModal() {
  const [activeTab, setActiveTab] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(12)
  const isLogin = localStorage.getItem('token')
  const modelSets = useAppSelector((state) => state.modalSet.exams)
  const modelCount = useAppSelector((state) => state.modalSet.countType)
  const user = useAppSelector((state) => state.student.profile)
  const total = useAppSelector((state) => state.modalSet.total)
  const result = useAppSelector((state) => state.modalSet.result)
  const [model_type, setModel_type] = useState('ALL')
  const [checkCount, setCheckCount] = useState(0)
  const [counter, setCounter] = useState(1)
  const [data, setData] = useState<any>([])
  const [isPaginationLoading, setIsPaginationLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleTab = async (index: number) => {
    setActiveTab(index)
    setIsPaginationLoading(true)
    setCounter(1)
    await fetchData(Switch[index], 12, 1)
    setIsPaginationLoading(false)
  }

  const fetchData = async (model_type: string = 'ALL', rowsPerPage: number = 12, page: number = 1) => {
    setIsLoading(true)
    try {
      const data: any = await apiService.getExamModel(dispatch, {
        model_type,
        rowsPerPage,
        page,
      })
    } catch (e) {
      navigate('/login')
    }

    if (data) {
      setData(data)
      setCheckCount(data.countType)

      if (data.countType > 10) {
        setCounter(Math.ceil(data.countType / limit))
      } else {
        setCounter(1)
      }
    }

    setIsLoading(false)
  }

  const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    const check = await fetchData(model_type, 12, value)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Top>
        <H2>Explore Our Model Sets</H2>
        <Textdiv>
          {Switch.map((item, index) => {
            return (
              <Btn
                onClick={() => {
                  handleTab(index)
                  setModel_type(item)
                  setPage(1)
                }}
                className={activeTab === index ? 'btnActive' : ''}
                key={item}
              >
                {item}
              </Btn>
            )
          })}
        </Textdiv>
      </Top>

      {data ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '30px 0',
            gap: '18px',
          }}
        >
          {!isLoading ? (
            modelSets && modelSets.length > 0 ? (
              modelSets.map((modalSet) => {
                return (
                  <Wrapper key={modalSet.id}>
                    {modalSet && (
                      <Container key={modalSet.id}>
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Body2
                            style={{
                              background: `${modalSet.type === 'free' ? 'red' : modalSet.manualEmails.includes(user!.email) || modalSet.paidEmails.includes(user!.email) ? 'green' : 'blue'}`,
                              color: 'white',
                              width: '60px',
                              height: '24px',
                              textAlign: 'center',
                              borderRadius: '20px',
                            }}
                          >
                            {modalSet.type.toUpperCase()}
                          </Body2>
                          {modalSet.paidEmails.includes(user!.email) ? (
                            <div
                              style={{
                                width: 'fit-content',
                                display: 'inline',
                                marginTop: '-9px',
                              }}
                            >
                              <img src={Esewa} height={20} />
                            </div>
                          ) : modalSet.manualEmails.includes(user!.email) ? (
                            <Typography
                              sx={{
                                marginTop: '-9px',
                                fontSize: '12px',
                              }}
                            >
                              M
                            </Typography>
                          ) : null}
                        </Box>

                        <Body1>{modalSet.title}</Body1>
                        <H5 color="grey">{modalSet.subtitle}</H5>
                        <H6
                          color="blue"
                          style={{
                            borderBottom: '1px dashed #D0D7E3',
                            paddingBottom: '15px',
                          }}
                        >
                          {modalSet.model_type}
                        </H6>

                        <PracticeNowButton
                          style={{
                            background: `${modalSet.type === 'free' ? 'red' : modalSet.manualEmails.includes(user!.email) || modalSet.paidEmails.includes(user!.email) ? 'green' : 'blue'}`,
                          }}
                          onClick={() => {
                            if (modalSet.type === 'free' || modalSet.manualEmails.includes(user!.email) || modalSet.paidEmails.includes(user!.email)) {
                              isLogin
                                ? navigate('/examineer', {
                                    state: {
                                      modelSetId: modalSet.id,
                                      modelSet: modalSet,
                                    },
                                  })
                                : navigate('/login')
                            } else {
                              navigate('/payment', {
                                state: {
                                  price: modalSet.price,
                                  modelSetId: modalSet.id,
                                },
                              })
                            }
                          }}
                        >
                          <Body2 style={{ color: '#FFFFFF' }}>
                            {modalSet.type === 'free' || modalSet.manualEmails.includes(user!.email) || modalSet.paidEmails.includes(user!.email) ? 'PRACTICE NOW' : 'BOOK NOW'}
                          </Body2>
                        </PracticeNowButton>
                      </Container>
                    )}
                  </Wrapper>
                )
              })
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h6" color="primary" component="h6" gutterBottom marginLeft={2}>
                  No ModelSet Available{' '}
                </Typography>
              </Box>
            )
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </div>
      ) : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          No Modelset Available.
        </Box>
      )}

      {/*----------------------------------- This section is for pagination ---------------------------------- */}
      {isPaginationLoading ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Pagination
          sx={{
            display: 'flex',
            padding: '30px',
            justifyContent: 'center',
          }}
          defaultPage={1}
          count={counter}
          shape="rounded"
          size="large"
          color="primary"
          onChange={handleChange}
        />
      )}
    </>
  )
}

export default ExamModal
