import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'
import { QuestionAns } from 'src/model/result'
import styled from 'styled-components'

const Box = styled.div`
  height: 92px;
  width: 112px;
  display: grid;
  place-items: center;
  z-index: 99;
  @media (max-width: 500px) {
    height: 50px;
    width: 80px;
  }
`
interface QuestionNumberProps {
  allQuestionAns: QuestionAns[]
  activeHandler: (step: number) => void
}

const QuestionNumber: FC<QuestionNumberProps> = ({ allQuestionAns, activeHandler }) => {
  const dispatch = useAppDispatch()
  const result = useAppSelector((state) => state.result.result)
  const id = localStorage.getItem('modalSetId')

  const navigate = useNavigate()

  useEffect(() => {
    if (!result) {
      apiService.getExamResult(dispatch, id!)
    }
  }, [dispatch, id, result])

  const questionClickHandler = (answer: any) => {
    navigate(`/examroom/${answer.id}`, { replace: true })
    activeHandler(0)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px',
        padding: '0 0 40px 0',
      }}
    >
      {!result && <h4>loading...</h4>}
      {allQuestionAns &&
        allQuestionAns
          .sort((a, b) => a.position - b.position)
          .map((answer, index) => {
            return (
              <Box
                key={index}
                onClick={() => questionClickHandler(answer)}
                style={{
                  fontSize: '18px',
                  background: answer.selectedAnswer !== '' ? '#0D5EF4' : '#F2F2F2',
                  color: answer.selectedAnswer !== '' ? '#fff' : '#151D26',
                }}
              >
                {answer.position}
              </Box>
            )
          })}
    </div>
  )
}

export default QuestionNumber
