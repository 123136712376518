import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { apiService } from "src/http/api-service";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SuccessfulImg from "src/common/images/successpay.webp";
const SuccessImg = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
  margin: 5rem 0;
`;

function SuccessPay() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const paymentVerification = async () => {
    const amount = searchParams.get("amt");
    const refId = searchParams.get("refId"); //productid
    const paymentId = searchParams.get("oid");

    try {
      await apiService.paymentVerificaiton({ amount, refId, paymentId });
    } catch (error) {
      alert("Failed");
    }
  };
  useEffect(() => {
    paymentVerification();
    const timer = setTimeout(() => {
      navigate("/exam");
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate("/exam");
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SuccessImg src={SuccessfulImg} alt="images" />
      <h4>Payment is successful.</h4>
    </div>
  );
}

export default SuccessPay;
