import styled from 'styled-components'

export const PracticeNowButton = styled.button`
  cursor: pointer;
  // width: 100%;

  padding: 10px 0;
  border-radius: 300px;
  // margin-top: 10px;
  border: none;
  background-color: ${(props) => (props.color === 'red' ? '#F20F10' : '#0D5EF4')};
`
