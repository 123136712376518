import { useEffect } from 'react'
import ClassStudentPhotoImg from 'src/common/images/class-student-photo.webp'
import StudentGroupPhoto from 'src/common/images/student-group-photo.webp'
import UBTExamPhoto from 'src/common/images/ubt-exam-img.webp'
import { RootWrapper } from 'src/common/wrapper/root-wrapper'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Pofile from './profile'

function Index() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const fetchSlider = async () => {
      const res = await apiService.getSlider(dispatch)
    }
    fetchSlider()
  }, [])

  const getSlider = useAppSelector((state) => state.slider.slider)

  return (
    <RootWrapper>
      <div
        style={{
          display: 'flex',
          columnGap: '30px',
          margin: '2.5em 0',
          width: '100%',
          position: 'relative',
        }}
      >
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          style={{
            width: '100%',
            objectFit: 'cover',
          }}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          loop
        >
          {getSlider.length > 0 ? (
            getSlider.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <div
                      style={{
                        width: '100%',
                        maxWidth: '1070px',
                        height: '100%',
                        maxHeight: '603px',
                        objectFit: 'cover',
                        zIndex: 0,
                      }}
                    >
                      <img src={item.image} alt="images" height="100%" width="100%" />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })
          ) : (
            <>
              <SwiperSlide>
                <div
                  style={{
                    width: '100%',
                    maxWidth: '1070px',
                    height: '100%',
                    maxHeight: '603px',
                    objectFit: 'cover',
                  }}
                >
                  <img src={StudentGroupPhoto} alt="images" height="100%" width="100%" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    width: '100%',
                    maxWidth: '1070px',
                    height: '100%',
                    maxHeight: '603px',
                    objectFit: 'cover',
                  }}
                >
                  <img src={UBTExamPhoto} alt="images" height="100%" width="100%" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    width: '100%',
                    maxWidth: '1070px',
                    height: '100%',
                    maxHeight: '603px',
                    objectFit: 'cover',
                  }}
                >
                  <img src={ClassStudentPhotoImg} alt="images" height="100%" width="100%" />
                </div>
              </SwiperSlide>
            </>
          )}
        </Swiper>

        <Pofile />
      </div>
    </RootWrapper>
  )
}

export default Index
