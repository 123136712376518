import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import angelKoreanLogo from 'src/common/images/black-logo.png'
import { Button, H4 } from 'src/common/theme/index'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { useSocket } from 'src/hooks/useSocket'
import { apiService } from 'src/http/api-service'
import { QuestionAns } from 'src/model/result'
import ExamNavbar from 'src/page-components/Exam/Exam-Question/exam-navbar'
import SolvedQuestion from 'src/page-components/Exam/Exam-Question/solved-question'
import TotalQuestion from 'src/page-components/Exam/Exam-Question/total-question'
import SingleQuestion from 'src/page-components/Exam/Exam-Question/total-question/single-question'
import UnsolvedQuestion from 'src/page-components/Exam/Exam-Question/unsolved-question'

export interface TimerCount {
  seconds: number
  minutes: number
}

export const ExamWrapper: React.FC = () => {
  const resultId = localStorage.getItem('resultId')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeStep, setActiveStep] = useState(1)
  const timer = useAppSelector((state) => state.result.result?.timer ?? 100)
  const socket = useSocket()
  const [open, setOpen] = useState(false)
  const questionAnswer = useAppSelector((state) => state.result.result?.questionAns)
  const [isPlaying, setIsPlaying] = useState<string | null>(null)
  const handlePlaying = (key: string) => setIsPlaying(key)

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const activeHandler = (step: number) => setActiveStep(step)

  const resetPlaying = () => setIsPlaying(null)

  const [question, setQuestion] = useState<QuestionAns>()
  const [currentValue, setCurrentValue] = useState(question?.selectedAnswer ?? '')

  const handleChange = async (value: string, questionId: string) => {
    setCurrentValue(value)
    try {
      await apiService.selectedAnswer(dispatch, {
        resultId: resultId!,
        question: questionId,
        selectedAns: value,
      })
    } catch (error) {
      // alert("Failed to update answer");
    }
  }

  useEffect(() => {
    const pathNameArray = location.pathname.split('/')
    const question = questionAnswer?.filter((q: any) => pathNameArray[pathNameArray.length - 1] === q.id)[0]
    if (questionAnswer) setQuestion(question)
  }, [location, questionAnswer])

  const submit = async () => {
    try {
      const response: any = await apiService.submitAnswer(dispatch, resultId!)
      if (!response.data) {
        alert('failed to submit answer')
        return
      }
      navigate('/stats', {
        state: {
          resultId: response.data?.id ?? 'jfepsjf',
        },
      })
    } catch (error) {
      alert('failed to submit answer')
    }
  }

  const expiryTimestamp = new Date()
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + timer / 1000)

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: async () => {
      if (timer === 0) {
        await apiService.submitAnswer(dispatch, resultId!)
        setTimeout(() => {
          navigate('/stats')
        }, 5000)
      }
    },
  })

  useEffect(() => {
    if (!socket) return
    socket.onmessage = (message: any) => {
      console.log(message, '[Received]')
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return
    if (seconds % 10 === 0) {
      socket.send(JSON.stringify({ minutes, seconds, resultId }))
    }
  }, [seconds])

  const nextQuestion = () => {
    if (!questionAnswer) return
    const currentQuestionIndex = questionAnswer?.findIndex((each: any) => each.id === question?.id)
    if (currentQuestionIndex === questionAnswer?.length - 1) return
    const updateQuestion = questionAnswer[currentQuestionIndex + 1]
    navigate(`/examroom/${updateQuestion!.id}`)
    setQuestion(updateQuestion)
    setIsPlaying(null)
    setCurrentValue(updateQuestion.selectedAnswer ?? '')
  }

  const previousQuestion = () => {
    if (!questionAnswer) return
    const currentQuestionIndex = questionAnswer?.findIndex((each: any) => each.id === question?.id)
    if (currentQuestionIndex === questionAnswer?.length) return
    const updateQuestion = questionAnswer[currentQuestionIndex - 1]
    navigate(`/examroom/${updateQuestion!.id}`)
    setQuestion(updateQuestion)
    setIsPlaying(null)
    setCurrentValue(updateQuestion.selectedAnswer ?? '')
  }

  return (
    <>
      <div style={{ position: 'sticky', top: '0', zIndex: '1000' }}>
        <ExamNavbar timer={{ seconds, minutes }} activeStep={activeStep} activeHandler={activeHandler} />
      </div>

      <div>
        <div
          style={{
            textAlign: 'center',
            position: 'fixed',
            opacity: '.1',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <H4>ANGEL KOREAN BHASA PVT</H4>
          <img src={angelKoreanLogo} alt="aangel korean logo" width="80%" />
        </div>
        {activeStep === 1 && <TotalQuestion activeHandler={activeHandler} />}
        {activeStep === 2 && <SolvedQuestion activeHandler={activeHandler} />}
        {activeStep === 3 && <UnsolvedQuestion activeHandler={activeHandler} />}
        {activeStep === 0 && question && (
          <SingleQuestion
            activeHandler={activeHandler}
            nextQuestion={nextQuestion}
            prevQuestion={previousQuestion}
            question={question}
            handlePlaying={handlePlaying}
            isPlaying={isPlaying}
            currentValue={currentValue}
            handleChange={handleChange}
            resetPlaying={resetPlaying}
            submit={submit}
            // questionAnswerLength={questionAnswer?.length ?? 0}
          />
        )}
      </div>

      {activeStep !== 0 && (
        <>
          <div
            style={{
              padding: '10px 40px',
              display: 'flex',
              position: 'sticky',
              bottom: '0',
              width: '100%',
              justifyContent: 'flex-end',
              zIndex: '99',
              background: '#fff',
            }}
          >
            <Button onClick={handleClickOpen} style={{ background: '#0D5EF4', border: 'none', color: 'white' }}>
              SUBMIT ANSWER
            </Button>
          </div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
            <DialogTitle sx={{ marginTop: '-30px' }}>Are you sure want to submit?</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} style={{ minHeight: '30px', width: '80px' }}>
                No
              </Button>
              <Button
                onClick={submit}
                // sx={{}}
                style={{
                  minHeight: '30px',
                  width: '80px',
                  marginRight: '18px',
                }}
              >
                yes
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}
