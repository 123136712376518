import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { theme } from 'src/common/theme'
import { store } from 'src/store'
import { ThemeProvider } from 'styled-components'
import App from './App'
import './index.css'

import 'swiper/css'

import 'swiper/css/scrollbar'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const client = new QueryClient()

root.render(
  <QueryClientProvider client={client}>
    <ToastContainer />
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </QueryClientProvider>
)
