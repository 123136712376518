import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Avatar } from '@mui/material'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Body1 } from 'src/common/theme'
import { TimerCount } from 'src/common/wrapper/exam-wrapper'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'
import Timer from 'src/page-components/timer/Timer'

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;

  @media (max-width: 1282px) {
    // display: none;
    padding: 8px;
    font-size: 16px;
  }
  @media (max-width: 1282px) {
    font-size: 10px;
  }
`

interface ExamNavbarProps {
  timer: TimerCount
  activeStep: number
  activeHandler: (step: number) => void
}

const ExamNavbar: React.FC<ExamNavbarProps> = ({ timer, activeStep, activeHandler }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.student.profile)
  const exam = useAppSelector((state) => state.result.result)

  const { data, isLoading } = useQuery([user, dispatch], () => {
    if (!user) {
      return apiService.getProfile(dispatch)
    }
  })

  const [solved, setSolved] = useState(0)
  const [unsolved, setUnsolved] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setSolved(exam?.questionAns?.filter((question: any) => question.selectedAnswer !== '').length ?? 0)
    setUnsolved(exam?.questionAns?.filter((question: any) => question.selectedAnswer === '').length ?? 0)
    setTotal(exam?.questionAns?.length ?? 0)
  }, [exam])

  return (
    <div
      style={{
        display: 'flex',
        background: '#FAFAFA',
        position: 'relative',
        border: '1px solid #E0E0E0',
      }}
    >
      <div style={{ width: '100%' }}>
        <Box>
          <Body1>{exam ? exam.exam.title : 'Online Exam'}</Body1>
          <Body1>Angel Korean Language</Body1>
          <Body1 style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {user?.name || 'User'}
            <Link to="/exam">
              {user && user.role === 'student' && (
                <Avatar
                  alt={user.name}
                  src={user.profile}
                  sx={
                    {
                      // border: '1px solid #0d5ef4',
                    }
                  }
                />
              )}
            </Link>
          </Body1>
        </Box>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
            borderTop: '1px solid #ededed',
            textAlign: 'center',
          }}
        >
          <Box
            style={{
              flex: '0 0 15%',
              maxWidth: '15%',
              height: '100%',
            }}
          >
            NEPAL
          </Box>
          {[
            { label: 'पढ्ने प्रस्नहरु', count: total },
            { label: 'समाधान भएका', count: solved },
            { label: 'समाधान नभएका', count: unsolved },
          ].map((each, index) => {
            return (
              <Body1
                style={{
                  background: activeStep === index + 1 ? '#0D5EF4' : 'none',
                  color: activeStep === index + 1 ? '#fff' : 'grey',
                  cursor: 'pointer',
                  padding: '8px 0',
                  flex: '0 0 25%',
                  borderLeft: '1px solid #ededed',
                  borderRight: '1px solid #ededed',
                }}
                onClick={() => activeHandler(index + 1)}
              >
                {each.label + ' '}
                <strong>({each.count})</strong>
              </Body1>
            )
          })}
          <Body1
            style={{
              color: '#0D5EF4',
              flex: '0 0 10%',
              maxWidth: '10%',
              padding: '0 20px 0 0 ',
            }}
          >
            <Timer countTimer={timer} />
          </Body1>
        </div>
      </div>
    </div>
  )
}

export default ExamNavbar
