import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "../../index.css";
import TextField from "src/common/input-field/TextField";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Formik } from "formik";
import { toast } from "react-toastify";

function OnlineForm() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useAppSelector((state) => state._class.classes);
  const [value, setValue] = React.useState();

  useEffect(() => {
    apiService.getCourse(dispatch, "online");
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          primary_number: "",
          secondary_number: "",
          course: "",
          submit: null,
        }}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          try {
            await apiService.applyForClass(dispatch, values);
            toast("Successfully applied for class enrollment", {
              type: "success",
            });
            resetForm();
            setLoading(false);
          } catch (error) {
            let errorMessage = "Failed to submit form";

            if (error) {
              errorMessage = (error as any).errors
                .map((err: any) => err.message)
                .join(" ");
            }

            setLoading(false);
          }
        }}
      >
        {({
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "grid",
                width: "100%",
                maxWidth: " 539px",
                gap: "25px",
              }}
            >
              <TextField
                className="login-form"
                type="text"
                name="name"
                required
                onChange={handleChange}
                placeholder="Your Name *"
                value={values.name}
              />
              <TextField
                className="login-form"
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Email Address*"
                value={values.email}
              />

              <TextField
                className="login-form"
                type="number"
                required
                name="secondary_number"
                onChange={handleChange}
                placeholder="Enter Phone Number *"
                value={values.secondary_number}
              />

              <select
                required
                className="login-form"
                onChange={(e) => {
                  setFieldValue("course", e.target.value);
                }}
              >
                <option value=""> Select Course</option>
                {classes.map((each: any) => {
                  return <option value={each.id}>{each.name}</option>;
                })}
              </select>
              <Button
                variant="contained"
                disabled={loading ? true : false}
                type="submit"
                style={{ width: "200px", height: "55px" }}
              >
                {loading ? "SUBMITTING..." : "SUBMIT"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default OnlineForm;
