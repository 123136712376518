import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import { Backdrop, Box, Drawer, Hidden, IconButton, List, ListItem, ListItemButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const drawerWidth = 240

interface dataProps {
  title: string
  link: string
}

const data: dataProps[] = [
  {
    title: 'HOME',
    link: '/',
  },

  {
    title: 'CLASSES',
    link: '/classes',
  },
  {
    title: 'EXAM',
    link: '/exam',
  },
  {
    title: 'ABOUT',
    link: '/about',
  },
  {
    title: 'CONTACT',
    link: '/contact',
  },
  {
    title: 'DOWNLOAD',
    link: '/download',
  },
  {
    title: 'TABLET MODE',
    link: '/tablet-mode',
  },
]

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),

  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,

      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

export default function HamburgerMenu() {
  const [open, setOpen] = useState(false)
  const [HiddenSideBar, setHiddenSideBar] = useState(false)

  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true)
    setHiddenSideBar(false)
  }

  const handleDrawerClose = () => {
    setOpen(!open)
  }

  const handleDrawerToggle = () => {
    setHiddenSideBar(true)
  }

  useEffect(() => {
    handleDrawerToggle()
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <IconButton aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ color: 'white' }}>
          <MenuIcon />
        </IconButton>

        <Hidden lgDown={HiddenSideBar}>
          <Backdrop open={open} onClick={handleDrawerClose} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                  borderRight: '1px dashed #0F2239',
                  background: '#fff',
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
              onClose={handleDrawerClose}
            >
              <List
                sx={{
                  height: '100%',
                  background: '#F3F7FB',
                }}
              >
                {data.map((item) => (
                  <ListItem key={item.title} disablePadding>
                    <ListItemButton
                      onClick={() => navigate(item.link)}
                      style={{
                        gap: '10px',
                        padding: '16px',
                      }}
                    >
                      <Typography variant="subtitle1" ml={2} sx={{ color: '#0F2239' }}>
                        {item.title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Backdrop>
        </Hidden>

        <Main open={open}>
          <Outlet />
        </Main>
      </Box>
    </>
  )
}
