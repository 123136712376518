import React from "react";
import HeroSection from "./hero-section/index";
import BookClass from "./book-class/index";
import AboutAngelKorean from "./about-angel-korean/index";
import Rating from "./rating/index";
import ModelSet from "./model-set";
import LatestNews from "./latest-news";
import Publication from "./publication";
import YoutubeAbout from "../about/learn/index";
import { HeaderFooter } from "src/App";
import { useAppSelector } from "src/hooks/hooks";
import { useNavigate } from "react-router-dom";
function Index() {
  // const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const user = useAppSelector((state: any) => state.student.profile);

  React.useEffect(() => {
    if (user && user.role === "tablet") {
      console.log(user.role);

      // setLoading(false);
      navigate("/tablet-mode");
    }
  }, [user]);

  // console.log(loading);
  return (
    <HeaderFooter>
      <HeroSection />
      <ModelSet />
      <BookClass />
      <YoutubeAbout />
      <Publication />
      <LatestNews />
      <AboutAngelKorean />
      <Rating />
    </HeaderFooter>
  );
}

export default Index;
