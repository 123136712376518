import { Button } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { H3, H5 } from "src/common/theme/index";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Main, Wrapper } from "./style/index";

function Stats() {
  const dispatch = useAppDispatch();
  const result = useAppSelector((state) => state.result.result);
  const user = useAppSelector((state) => state.student.profile);

  const navigate = useNavigate();
  const id = (useLocation().state as any).resultId;

  const { data } = useQuery([dispatch, id], () => {
    if (id) {
      apiService.getReview(dispatch, id);
    }
  });

  return (
    <>
      <div
        style={{
          padding: "10px 0",
          textAlign: "center",
          background: "#0A2D6F",
        }}
      >
        <H3 style={{ color: "#FFFFFF" }}>Test completed</H3>
      </div>
      <Main>
        <Wrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5>Score</H5>
            <H5 style={{ color: "green" }}>
              {(
                (result?.correctAnswers! / result?.totalQuestions!) *
                100
              ).toFixed(2)}
              /100
            </H5>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px 0",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <H5>Correct Answers</H5>
            <H5 style={{ color: "green" }}>
              {result?.correctAnswers}/{result?.totalQuestions}
            </H5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px dotted grey",
              paddingTop: "15px",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            {user && user?.role === "student" && (
              <Button
                onClick={() => navigate("/mytest")}
                variant="contained"
                sx={{ padding: "8px 20px", background: "rgb(10, 45, 111)" }}
              >
                Home
              </Button>
            )}

            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ padding: "8px 20px" }}
              onClick={() => navigate(`/mytest/${id}`)}
            >
              Review
            </Button>
          </div>
        </Wrapper>
      </Main>
    </>
  );
}

export default Stats;
