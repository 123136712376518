export enum TypeEnum {
  free = 'free',
  paid = 'paid',
}

enum Model_TypeEnum {
  CBT = 'CBT',
  UBT = 'UBT',
}

export enum QuestionEnum {
  text = 'text',
  image = 'image',
  audio = 'audio',
  imgAudio = 'image-audio',
}

export interface ModelQuestion {
  id: string
  question: string
  type: QuestionEnum
  option_A: string
  option_B: string
  option_C: string
  option_D: string
  option_type: QuestionEnum
  correct_answer: string
}

export interface ModelSet {
  id: string
  title: string
  subtitle: string
  isFeatured: boolean
  modelSetQuestions: ModelQuestion[]
  paidEmails: string[]
  manualEmails: string[]
  type: TypeEnum
  price: number
  model_type: Model_TypeEnum
}
