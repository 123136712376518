import styled from 'styled-components'

export const theme = {
  palette: {
    primary: {
      main: '#00306E',
      light: '#3478F6',
    },
    warning: {
      main: '#F20F10',
    },
    secondary: {
      main: '#4d5765 ',
    },
    white: {
      main: '#FFFFFF',
    },
    smoke: {
      main: '#F3F7FB',
    },
  },
}

export const H1 = styled.h1`
  font-size: 64px;
  line-height: 1.156;
  font-weight: 700;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};

  @media (max-width: 600px) {
    font-size: 40px;
  }
`

export const H2 = styled.h2`
  font-size: 44px;
  line-height: 1.227;
  font-weight: 700;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};

  @media (max-width: 600px) {
    font-size: 34px;
  }
`

export const H3 = styled.h3`
  font-size: 36px;
  line-height: 1.277;
  font-weight: 700;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
  @media (max-width: 600px) {
    font-size: 25px;
  }
`

export const H4 = styled.h4`
  font-size: 30px;
  line-height: 1.333;
  font-weight: 600;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
`

export const H5 = styled.h5`
  font-size: 24px;
  line-height: 1.416;
  font-weight: 600;
  @media (max-width: 565px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
`

export const H6 = styled.h6`
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
`

export const Subtitle1 = styled.p`
  font-size: 18px;
  line-height: 1.444;
  font-weight: 500;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
`

export const Body1 = styled.p`
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
  @media (max-width: 550px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
`

export const Body2 = styled.p`
  font-size: 14px;
  line-height: 1.71;
  font-weight: 400;
  color: ${(props) => {
    if (props.color === 'white') {
      return '#FFFFFF'
    } else if (props.color === 'red') {
      return '#F20F10'
    } else if (props.color === 'black') {
      return ' #1A1A1A'
    } else if (props.color === 'blue') {
      return '#3478F6'
    } else if (props.color === 'dark-blue') {
      return '#00306E'
    } else if (props.color === 'grey') {
      return '#4d5765'
    } else {
      return '#0F2239'
    }
  }};
`

export const UnderLine = styled.div`
  background: #0d5ef4;
  width: 60px;
  height: 3px;
  margin: 5px 0 30px 0;
`

export const Option = styled.button`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: none;
  border: 1px solid #828282;
  border-radius: 8px;
`

export const SingleQuestionWrapper = styled.div`
  // height: 80vh;
  display: flex;
  padding: 80px;
  align-items: center;
  justify-content: center;
  gap: 60px;

  flex-wrap: wrap;

  @media (max-width: 1282px) {
    padding: 16px;
    padding: 40px;
  }
  @media (max-width: 630px) {
    display: grid;
    padding: 16px;
    width: 100%;
  }
`

export const Box = styled.div`
  border: 1px solid #828282;
  border-radius: 5px;
  width: 50%;
  padding: 15px;
  @media (max-width: 680px) {
    width: 100%;
  }
`

export const Stack = styled.div`
  width: 40%;
  @media (max-width: 630px) {
    width: 100%;
  }
`

export const Button = styled.button`
  padding: 15px 60px;
  border-radius: 4px;
  &hover: {
    opacity: 0.7;
  }

  @media (max-width: 1282px) {
    padding: 10px 30px;
  }

  @media (max-width: 710px) {
    padding: 10px 30px;
  }

  @media (max-width: 540px) {
    padding: 5px 20px;
  }
`
export const NextPreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  padding: 20px 100px;
  margin-top: 100px;
  justify-content: space-between;

  @media (max-width: 1282px) {
    padding: 10px 50px;
    margin: 0;
  }
  @media (max-width: 540px) {
    padding: 8px 16px;
    margin: 0;
  }
`
