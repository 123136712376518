import React, { useState } from "react";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { Body1, H2, UnderLine } from "src/common/theme";
import { Button, CircularProgress } from "@mui/material";
import TextField from "src/common/input-field/TextField";
import { useNavigate, NavLink } from "react-router-dom";
import { Formik } from "formik";
import { apiService } from "src/http/api-service";
import { toast } from "react-toastify";
import { HeaderFooter } from "src/App";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  return (
    <HeaderFooter>
      <RootWrapper>
        <div
          style={{
            margin: "100px auto",
            padding: "0 20px",
            // textAlign: "center",
            width: "100%",
            maxWidth: "1155px",
            height: "789px",
            background: "#F3F7FB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid red",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "549px",
              textAlign: "center",
              // border: "1px solid",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <H2>Login</H2>
              <UnderLine />
              <Body1>Enter the following details to login your account!</Body1>
            </div>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={async (values) => {
                setLoading(true);
                try {
                  const res: any = await apiService.loginStudent(values);
                  res?.data && navigate("/mytest");
                  localStorage.setItem("token", res.accessToken);
                  localStorage.setItem("id", res.data.id);

                  toast("Successfully login!", {
                    type: "success",
                  });

                  setTimeout(() => {
                    res.data.role === "student" && navigate("/mytest");
                    res.data.role === "tablet" && navigate("/tablet-mode");
                    //  navigate("/mytest", { replace: true });
                  }, 100);
                } catch (error) {
                  let errorMessage = "Failed to login!";

                  if (error) {
                    errorMessage = (error as any).errors
                      .map((err: any) => err.message)
                      .join(" ");
                  }
                  toast(errorMessage, {
                    type: "error",
                  });

                  setLoading(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "25px",
                      marginTop: "30px",
                    }}
                  >
                    <TextField
                      type="email"
                      required
                      name="email"
                      onChange={handleChange}
                      className="login-form"
                      placeholder="Email Address/Phone Number *"
                      value={values.email}
                    />

                    <TextField
                      type="password"
                      required
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      className="login-form"
                      value={values.password}
                    />

                    <Button
                      disabled={loading ? true : false}
                      type="submit"
                      variant="contained"
                    >
                      {loading ? <CircularProgress /> : "LOGIN"}
                    </Button>

                    <Body1>
                      Don't have an account?
                      <NavLink to={"/signup"}> Register</NavLink>
                    </Body1>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </RootWrapper>
    </HeaderFooter>
  );
}

export default Login;
