import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { East } from "@mui/icons-material";
import { H2, Body1 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import styled from "styled-components";

const ClassWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin: 100px 0;
  max-width: @media (max-width: 1920px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 1536px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 1200px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 900px) {
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
  @media (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
`;

function ClassType(props: {
  image?: string;
  title: string;
  button: ReactNode;
  info: string;
  paths: string;
  background?: "red" | "blue";
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
}) {
  return (
    <RootWrapper>
      <ClassWrapper
        style={{
          flexDirection: props.flexDirection,
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "727px",
            objectFit: "cover",
            height: "100%",
          }}
        >
          <img src={props.image} alt="images" height="100%" width="100%" />
        </div>
        <div style={{ maxWidth: "444px" }}>
          <H2>{props.title}</H2>
          <Body1 style={{ paddingTop: "15px" }} color="grey">
            {props.info}
          </Body1>
          <Link to={props.paths}>
            <Button
              variant="contained"
              endIcon={<East />}
              style={{
                marginTop: "20px",
                padding: "15px 30px",
                background:
                  props["background"] === "red" ? "#F20F10" : "#0D5EF4",
              }}
            >
              {props.button}
            </Button>
          </Link>
        </div>
      </ClassWrapper>
    </RootWrapper>
  );
}

ClassType.defaultProps = {
  props: {
    flexDirection: "column",
  },
};

export default ClassType;
