import { Button } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body1, H2, H5, H6 } from 'src/common/theme'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'
import { Container, Top, Wrapper } from '../Exam/Exam-Modal/stye'

const TabletMode = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const tabletModeModelSet = useAppSelector((state) => state.tablet_mode.tabletMode)

  useEffect(() => {
    const fetch = async () => {
      const res = await apiService.getTabletMode(dispatch)
      console.log(res, 'tablet')
    }
    fetch()
  }, [])
  useEffect(() => {
    const fetch = async () => {
      const res = await apiService.getProfile(dispatch)
      console.log(res, 'student')
    }
    fetch()
  }, [])

  return (
    <>
      <Top>
        <H2>Explore Our Tablet Mode</H2>
      </Top>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '18px',
          margin: '30px 0',
        }}
      >
        {tabletModeModelSet.map((item: any) => {
          return (
            <Wrapper key={item.id}>
              <Container>
                <Body1>{item.title}</Body1>
                <H5 color="grey">{item.subtitle}</H5>
                <H6
                  color="blue"
                  style={{
                    borderBottom: '1px dashed #D0D7E3',
                    paddingBottom: '15px',
                  }}
                >
                  {item.model_type}
                </H6>

                <Button
                  variant="contained"
                  sx={{ background: '#ff0000' }}
                  onClick={() =>
                    navigate('/examineer', {
                      state: {
                        modelSetId: item.id,
                        modelSet: item,
                      },
                    })
                  }
                >
                  Start Exam
                </Button>
              </Container>
            </Wrapper>
          )
        })}
      </div>
    </>
  )
}

export default TabletMode
