import styled from "styled-components";
import ContactFormImage from "src/common/images/background-image.webp";

export const Typography = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 23px;
  color: #0d5ef4;
`;

export const ContactBox = styled.div`
  display: flex;
  object-fit: cover;
  position: relative;
  bottom: 550px;
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 335px;
  min-height: 74px;
`;

export const Stack = styled.div`
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1380px;
  place-items: center;
  min-height: 300px;
  background: #f0f5fb;
  margin-top: 50px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 700px;
  background: #f0f5fb;
`;

export const Main = styled.div`
  margin: 100px 0;
  width: 100%;
  max-width: 1380px;
  height: 100%;
  background-size: cover;
  background-image: url(${ContactFormImage});
  min-height: 600px;
  padding: 100px;
  background-color: #f3f7fb;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
  height: 100%;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
`;
export const Div = styled.div`
  display: flex;
  gap: 25px;
`;

export const ContactWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  place-items: center;
  justify-content: center;
  place-items: center;
  margin-top: 20px;
  background-size: cover;
  @media (max-width: 600px) {
    display: hidden;
  }
`;

export const PhysicalFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 25px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

export const PaymentWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  maxwidth: 500px;
  margintop: 50px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex-wrap: wrap;
    width: 100%;
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
`;
