import { useEffect } from 'react'
import { LeftBar, RightBar } from 'src/common/svg'
import { H2 } from 'src/common/theme'
import { RootWrapper } from 'src/common/wrapper/root-wrapper'
import { useAppDispatch } from 'src/hooks/hooks'
import { apiService } from 'src/http/api-service'
import styled from 'styled-components'
import TabletMode from './tablet-mode'

const Header = styled.div`
  margin-top: 20px;
  display: grid;
  place-items: center;
  position: relative;
  height: 300px;
  width: 100%;
  background: #00306e;
  color: #ffffff;
  @media (max-width: 900px) {
    height: 200px;
  }
  @media (max-width: 600px) {
    height: 100px;
  }
`

const LeftBarWrapper = styled.div`
  width: 194px;
  height: 215px;
  position: absolute;
  top: -20px;
  left: -100px;
  @media (max-width: 800px) {
    width: 150px;
    height: 171px;
    position: absolute;
    top: -20px;
    left: -90px;
  }
  @media (max-width: 600px) {
    width: 100px;
    height: 79px;
    position: absolute;
    top: -20px;
    left: -30px;
  }
`

const RightBarWrapper = styled.div`
  width: 166px;
  height: 165px;
  position: absolute;
  top: 100px;
  right: 0;
  @media (max-width: 800px) {
    width: 122px;
    height: 143px;
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 600px) {
    width: 72px;
    height: 94px;
    position: absolute;
    top: 0;
    right: 0;
  }
`

const Index = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      const response = await apiService.getProfile(dispatch)
      // console.log(res, "student");
    }
    fetch()
  }, [])

  return (
    // <HeaderFooter>
    <>
      <Header>
        <LeftBarWrapper>
          <LeftBar />
        </LeftBarWrapper>
        <RightBarWrapper>
          <RightBar />
        </RightBarWrapper>
        <H2 color="white">TABLET MODE</H2>
      </Header>
      <RootWrapper>
        <TabletMode />
      </RootWrapper>
    </>
    // </HeaderFooter>
  )
}

export default Index
