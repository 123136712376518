import styled from 'styled-components'

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  column-gap: 10px;
  flex-wrap: wrap;
  padding: 20px;
`
