import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Body1 } from 'src/common/theme'
import { RootWrapper } from 'src/common/wrapper/root-wrapper'
import { useAppSelector } from 'src/hooks/hooks'
import HamburgerMenu from '../../hamburger-menu/HamburgerMenu'
import { EnrollButton, MenuWrapper, NavWrapper } from '../style'

function SecondHeader() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [active, setActive] = useState(false)
  const role = useAppSelector((state) => state.student.profile)

  const handleSetActive = () => {
    setActive(!active)
  }

  return (
    <RootWrapper>
      <div
        style={{
          display: 'flex',
          height: '60px',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <NavWrapper>
          <NavLink to="/">
            <Body1 color="white" onClick={handleSetActive}>
              HOME
            </Body1>
            {active && (
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'white',
                  marginTop: '5px',
                }}
              />
            )}
          </NavLink>
          <NavLink to="/classes">
            <Body1 color="white">CLASSES</Body1>
            {active && (
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'white',
                  marginTop: '5px',
                }}
              />
            )}
          </NavLink>
          <NavLink to="/exam">
            <Body1 color="white">EXAM</Body1>
            {active && (
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'white',
                  marginTop: '5px',
                }}
              />
            )}
          </NavLink>
          {/* <NavLink to="#">
            <Body1 color="white">NEWS</Body1 >
          </NavLink> */}
          <NavLink to="/about">
            <Body1 color="white">ABOUT</Body1>
            {active && (
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'white',
                  marginTop: '5px',
                }}
              />
            )}
          </NavLink>
          <NavLink to="/contact">
            <Body1 color="white">CONTACT</Body1>
            {active && (
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'white',
                  marginTop: '5px',
                }}
              />
            )}
          </NavLink>
          <NavLink to="/download">
            <Body1 color="white">DOWNLOAD</Body1>
            {active && (
              <div
                style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: 'white',
                  marginTop: '5px',
                }}
              />
            )}
          </NavLink>
          {role?.role === 'tablet' && (
            <NavLink to="/tablet-mode">
              <Body1 color="white">TABLET MODE</Body1>
              {active && (
                <div
                  style={{
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'white',
                    marginTop: '5px',
                  }}
                />
              )}
            </NavLink>
          )}
        </NavWrapper>
        <MenuWrapper>
          <HamburgerMenu />
        </MenuWrapper>

        {!token && <EnrollButton onClick={() => navigate('/login')}>ENROLL NOW</EnrollButton>}
      </div>
    </RootWrapper>
  )
}

export default SecondHeader
