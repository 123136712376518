import React, { useState, useRef, useEffect } from "react";
import TextField from "src/common/input-field/TextField";
import { Body1, H6, H4 } from "src/common/theme";
import { useDispatch } from "react-redux";
import { apiService } from "src/http/api-service";
import { Formik } from "formik";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import {
  Container,
  Div,
  FormWrapper,
  Main,
  Wrapper,
} from "./style/personalDetailStyle";
import { Button } from "@mui/material";
import { commonService } from "src/http/common-service";
import { useAppSelector } from "src/hooks/hooks";
import { LogoSvg } from "src/common/svg";

interface UserProps {
  edit?: boolean;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
}

function MyProfile({ edit, id, setModal }: UserProps) {
  const dispatch = useDispatch();
  const refChooseFile = useRef<any>();
  const user = useAppSelector((state: any) => state.student.profile);
  console.log(user);
  const [fileUrl, setFileUrl] = useState("");

  async function uploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    const formData = new FormData();
    formData.append("profile", e.target.files![0]);
    try {
      const fileUrl = await commonService.uploadProfile(formData);
      setFileUrl(fileUrl);

      await apiService.updateProfile(dispatch, {
        ...user,
        profile: fileUrl,
      });

      toast("Successfully uploaded profile", {
        type: "success",
      });
    } catch (error) {
      toast("Failed to upload profile", {
        type: "error",
      });
    }
  }

  const { data, isLoading } = useQuery([id, dispatch], () =>
    apiService.getProfile(dispatch)
  );

  return (
    <>
      {data ? (
        <>
          <Wrapper>
            <Main>
              <Container>
                <Body1
                  color="blue"
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  {/* Edit picture */}
                  <div
                    style={{
                      objectFit: "cover",
                      width: "fit-content",
                      height: "auto",
                    }}
                  >
                    {/* <img
                      src={user.profile}
                      width="175"
                      height="175"
                      style={{ objectFit: "cover" }}
                    /> */}

                    {!fileUrl ? (
                      <div
                        style={{
                          height: "175",
                          width: "175",
                          padding: "43px",
                          border: "1px solid",
                        }}
                      >
                        <LogoSvg />
                      </div>
                    ) : (
                      <img
                        src={user?.profile}
                        width="175"
                        height="175"
                        alt="User photo"
                        // style={{ objectFit: "cover" }}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    onChange={(e) => uploadFile(e)}
                    ref={refChooseFile}
                    style={{ display: "none" }}
                  />
                  <button
                    onClick={() => refChooseFile.current.click()}
                    style={{
                      padding: "5px 45px",
                      border: "none",
                      marginTop: "5px",
                      borderRadius: "2px",
                    }}
                  >
                    Upload Photo
                  </button>
                </Body1>

                <div>
                  <H6
                    style={{
                      color: "#1A1A1A",
                      padding: "20px 0px",
                    }}
                  >
                    Personal Details
                  </H6>
                </div>
                <Formik
                  initialValues={{
                    name: user ? user.name : "",
                    // profile: user ? user.profile : "",
                    email: user ? user.email : "",
                    primary_number: user ? user.primary_number : "",
                    dob: user ? user.dob : "",
                  }}
                  onSubmit={async (
                    values,

                    { resetForm, setErrors, setStatus, setSubmitting }
                  ) => {
                    if (data) {
                      try {
                        await apiService.updateProfile(dispatch, {
                          ...values,
                          profile: fileUrl,
                        });
                        toast("Successfully updated profile", {
                          type: "success",
                        });
                      } catch (error) {
                        toast("Failed to update profile", {
                          type: "error",
                        });
                      }
                    }
                  }}
                >
                  {({
                    setFieldValue,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <FormWrapper>
                        <Div>
                          <label>Full name</label>
                          <TextField
                            style={{ maxWidth: "600px", width: "100%" }}
                            type="text"
                            name="name"
                            // error={Boolean(touched.name && errors.name)}
                            className="Profile-form"
                            onChange={handleChange}
                            value={values.name}
                          />
                          <br />
                        </Div>
                        <Div>
                          <label>Email address</label>
                          <TextField
                            style={{ maxWidth: "600px", width: "100%" }}
                            type="text"
                            name="email"
                            className="Profile-form"
                            onChange={handleChange}
                            value={values.email}
                          />
                          <br />
                        </Div>
                        <Div>
                          <label>Phone number</label>
                          <TextField
                            style={{ maxWidth: "600px", width: "100%" }}
                            type="text"
                            name="primary_number"
                            placeholder="Enter number"
                            className="Profile-form"
                            onChange={handleChange}
                            value={values.primary_number}
                          />
                          <br />
                        </Div>
                        <Div>
                          <label>Date of Birth</label>
                          <TextField
                            style={{ maxWidth: "600px", width: "100%" }}
                            type="text"
                            name="dob"
                            placeholder="DD/MM/YYYY"
                            className="Profile-form"
                            onChange={handleChange}
                            value={values.dob}
                          />
                        </Div>
                        {/* <Div>
                          <label>Gender</label>
                          <TextField
                          // style={{maxWidth: '600px'}}
                            type="text"
                            placeholder="Male"
                            className="Profile-form"
                          />
                        </Div> */}
                      </FormWrapper>
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          marginTop: "20px",
                          minHeight: "2.5rem",
                          width: "150px",
                        }}
                      >
                        UPDATE
                      </Button>
                    </form>
                  )}
                </Formik>
              </Container>
            </Main>
          </Wrapper>
        </>
      ) : isLoading ? (
        <H4 style={{ margin: "40px 0" }}>Loading...</H4>
      ) : (
        <H4 style={{ margin: "40px 0" }}>Profile not found.</H4>
      )}
    </>
  );
}

export default MyProfile;
