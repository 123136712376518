import { RadioGroup } from '@mui/material'
import { AnswerOption } from 'src/common/answerOption'
import Audio from 'src/common/answerOption/audio'
import { Option } from 'src/common/theme'
import { Box, Button, H6, NextPreviewWrapper, SingleQuestionWrapper, Stack } from 'src/common/theme/index'
import { QuestionEnum } from 'src/model/modelSet'
import { QuestionAns } from 'src/model/result'

interface SingleQuestionProps {
  question: QuestionAns
  activeHandler: (step: number) => void
  nextQuestion: () => void
  prevQuestion: () => void
  handlePlaying: (key: string) => void
  isPlaying: string | null
  currentValue: string
  handleChange: (value: string, questionId: string) => void
  resetPlaying: () => void
  submit: () => void
}

export default function SingleQuestion({
  question,
  activeHandler,
  nextQuestion,
  prevQuestion,
  isPlaying,
  handlePlaying,
  currentValue,
  handleChange,
  resetPlaying,
  submit,
}: // questionAnswerLength,
SingleQuestionProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SingleQuestionWrapper>
        <Box>
          <H6 style={{ fontWeight: '400' }}>
            {question.questionGroup?.min}-{question.questionGroup?.max}.{question.questionGroup?.title}
          </H6>
          <br />
          <div
            style={{
              border: '1px dashed #828282',
              padding: ' 10px',
              borderRadius: '10px',
            }}
          >
            <H6 style={{ fontWeight: '400' }}>
              {question.position}.<br />
              <div
                dangerouslySetInnerHTML={{
                  __html: question.question,
                }}
              ></div>
            </H6>

            <div style={{ width: '100%', maxWidth: '50%' }}>
              {question.type === QuestionEnum.image && question.image ? (
                <div
                  style={{
                    textAlign: 'center',
                    margin: '20px',
                    maxWidth: '550px',
                    width: '100%',
                    minHeight: '100px',
                    objectFit: 'cover',
                  }}
                >
                  <img height="100%" width="100%" alt="testing" src={question.image!} />
                </div>
              ) : question.type === QuestionEnum.audio && question.audio ? (
                <Audio source={question.audio!} isPlayed={question.isQuestionAudioPlayed ?? false} isPlaying={isPlaying} optionKey="isQuestionAudioPlayed" setPlaying={handlePlaying} />
              ) : (
                question.type === QuestionEnum.imgAudio && (
                  <div>
                    <div
                      style={{
                        textAlign: 'center',
                        margin: '20px',
                        maxWidth: '550px',
                        width: '100%',
                        minHeight: '100px',
                        objectFit: 'cover',
                      }}
                    >
                      <img height="100%" width="100%" alt="testing" src={question.image!} />
                    </div>
                    <div style={{ background: '#D9D9DC', borderRadius: '8px' }}>
                      <Audio source={question.audio!} isPlayed={question.isQuestionAudioPlayed ?? false} isPlaying={isPlaying} optionKey="isQuestionAudioPlayed" setPlaying={handlePlaying} />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </Box>
        <Stack>
          <RadioGroup
            style={{
              rowGap: '18px',
              flexDirection: question.option_type === 'image' ? 'row' : 'column',
              justifyContent: 'space-between',
            }}
          >
            {question &&
              Object.keys(question)
                .filter((key) => key.split('_')[0] === 'option' && key !== 'option_type')
                .map((key, index) => (
                  <Option
                    key={key}
                    style={{
                      background: currentValue === key ? '#0D5EF4' : '',
                      color: currentValue === key ? '#ffffff' : '',
                      flex: question.option_type === 'image' ? '0 0 calc(50% - 10px)' : '100%',
                      maxWidth: question.option_type === 'image' ? 'calc(50% - 10px)' : '100%',
                      position: 'relative',
                    }}
                  >
                    <AnswerOption
                      key={key}
                      type={question.option_type}
                      index={index + 1}
                      label={(question as any)[key]}
                      isChecked={currentValue === key}
                      source={(question as any)[key]}
                      clicked={() => handleChange(key, question.id)}
                      isPlayed={(question as any)[`isOption_${key.split('_')[1]}_AudioPlayed`]}
                      isPlaying={isPlaying}
                      setPlaying={handlePlaying}
                      optionKey={`isOption_${key.split('_')[1]}_AudioPlayed`}
                    />
                  </Option>
                ))}
          </RadioGroup>
        </Stack>
      </SingleQuestionWrapper>
      <NextPreviewWrapper>
        {question.position !== 1 && (
          <Button onClick={prevQuestion} style={{ background: 'grey', border: 'none', color: 'white' }}>
            आघिग्लो
          </Button>
        )}

        <Button
          onClick={() => {
            activeHandler(1)
            resetPlaying()
          }}
          style={{
            position: 'sticky',
            bottom: '0',
            color: 'black',
            background: '#fff',
            border: '1px solid grey',
          }}
        >
          सबै प्रस्नहरु
        </Button>
        {question.position !== 40 && (
          <Button onClick={nextQuestion} style={{ background: '#0D5EF4', border: 'none', color: 'white' }}>
            अर्को
          </Button>
        )}
        {question.position === 40 && <Button onClick={submit}>SUBMIT ANSWER</Button>}
      </NextPreviewWrapper>
    </div>
  )
}
